import React, { useState, useEffect, FormEvent, ChangeEvent } from 'react';
import styled from 'styled-components';

interface PasswordContainerProps extends React.HTMLAttributes<HTMLDivElement> {}

const PasswordContainer = styled.div.attrs<PasswordContainerProps>(() => ({}))<PasswordContainerProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f5f5f5;
`;

interface PasswordFormProps extends React.FormHTMLAttributes<HTMLFormElement> {
  onSubmit: (e: FormEvent<HTMLFormElement>) => void;
}

const PasswordForm = styled.form.attrs<PasswordFormProps>(({ onSubmit }) => ({
  onSubmit,
}))<PasswordFormProps>`
  background: white;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
`;

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  value: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

const Input = styled.input.attrs<InputProps>(({ value, onChange, placeholder, required }) => ({
  type: 'password',
  value,
  onChange,
  placeholder,
  required,
}))<InputProps>`
  width: 100%;
  padding: 0.75rem;
  margin: 0.5rem 0;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
`;

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode;
}

const Button = styled.button.attrs<ButtonProps>(({ children }) => ({
  type: 'submit',
  children,
}))<ButtonProps>`
  width: 100%;
  padding: 0.75rem;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  margin-top: 1rem;

  &:hover {
    background-color: #0056b3;
  }
`;

interface ErrorMessageProps extends React.HTMLAttributes<HTMLParagraphElement> {
  children: React.ReactNode;
}

const ErrorMessage = styled.p.attrs<ErrorMessageProps>(({ children }) => ({
  children,
}))<ErrorMessageProps>`
  color: red;
  margin-top: 1rem;
  text-align: center;
`;

interface PasswordProtectionProps {
  children: React.ReactNode;
  password?: string;
}

interface PasswordProtectionState {
  isAuthenticated: boolean;
  password: string;
  error: string;
}

const PasswordProtection: React.FC<PasswordProtectionProps> = ({ children, password = 'flippose2024secure' }) => {
  const [state, setState] = useState<PasswordProtectionState>({
    isAuthenticated: false,
    password: '',
    error: '',
  });

  useEffect(() => {
    const isAuth = localStorage.getItem('maintenance_auth') === 'true';
    setState(prev => ({ ...prev, isAuthenticated: isAuth }));
  }, []);

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (state.password === password) {
      setState(prev => ({ ...prev, isAuthenticated: true, error: '' }));
      localStorage.setItem('maintenance_auth', 'true');
    } else {
      setState(prev => ({ ...prev, error: 'Incorrect password' }));
    }
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setState(prev => ({ ...prev, password: e.target.value, error: '' }));
  };

  if (state.isAuthenticated) {
    return <>{children}</>;
  }

  return (
    <PasswordContainer>
      <PasswordForm onSubmit={handleSubmit}>
        <h2 style={{ marginBottom: '1rem', textAlign: 'center' }}>Maintenance Mode</h2>
        <p style={{ marginBottom: '1rem', textAlign: 'center' }}>
          This site is currently under maintenance. Please enter the password to access.
        </p>
        <Input
          value={state.password}
          onChange={handleChange}
          placeholder="Enter password"
          required
        />
        {state.error && <ErrorMessage>{state.error}</ErrorMessage>}
        <Button>Access Site</Button>
      </PasswordForm>
    </PasswordContainer>
  );
};

export default PasswordProtection;
