import { ReportHandler } from 'web-vitals';
import { analytics } from './firebase';
import { logEvent, Analytics } from 'firebase/analytics';

const reportWebVitals = (onPerfEntry?: ReportHandler) => {
  if (onPerfEntry && onPerfEntry instanceof Function) {
    import('web-vitals').then(({ getCLS, getFID, getFCP, getLCP, getTTFB }) => {
      getCLS(metric => {
        onPerfEntry(metric);
        // Log to analytics if available
        if (analytics) {
          logEvent(analytics as Analytics, 'web_vital', {
            metric_name: 'CLS',
            metric_value: metric.value,
            metric_delta: metric.delta,
          });
        }
      });
      getFID(metric => {
        onPerfEntry(metric);
        // Log to analytics if available
        if (analytics) {
          logEvent(analytics as Analytics, 'web_vital', {
            metric_name: 'FID',
            metric_value: metric.value,
            metric_delta: metric.delta,
          });
        }
      });
      getFCP(metric => {
        onPerfEntry(metric);
        // Log to analytics if available
        if (analytics) {
          logEvent(analytics as Analytics, 'web_vital', {
            metric_name: 'FCP',
            metric_value: metric.value,
            metric_delta: metric.delta,
          });
        }
      });
      getLCP(metric => {
        onPerfEntry(metric);
        // Log to analytics if available
        if (analytics) {
          logEvent(analytics as Analytics, 'web_vital', {
            metric_name: 'LCP',
            metric_value: metric.value,
            metric_delta: metric.delta,
          });
        }
      });
      getTTFB(metric => {
        onPerfEntry(metric);
        // Log to analytics if available
        if (analytics) {
          logEvent(analytics as Analytics, 'web_vital', {
            metric_name: 'TTFB',
            metric_value: metric.value,
            metric_delta: metric.delta,
          });
        }
      });
    });
  }
};

export default reportWebVitals;
