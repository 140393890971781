import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Container } from '../common';
import { useTranslation } from 'react-i18next';

const FooterWrapper = styled.footer`
  background-color: var(--surface);
  padding: 4rem 0;
  margin-top: auto;
`;

interface FooterGridProps extends React.HTMLAttributes<HTMLDivElement> {
  $columns: { desktop: number; tablet: number; mobile: number };
  $gap: string;
}

const FooterGrid = styled.div.attrs<FooterGridProps>(() => ({}))<FooterGridProps>`
  display: grid;
  grid-template-columns: repeat(${props => props.$columns.desktop}, 1fr);
  gap: ${props => props.$gap === 'xl' ? '2rem' : '1rem'};

  @media (max-width: 768px) {
    grid-template-columns: repeat(${props => props.$columns.tablet}, 1fr);
  }

  @media (max-width: 480px) {
    grid-template-columns: repeat(${props => props.$columns.mobile}, 1fr);
  }
`;

const FooterSection = styled.div`
  h4 {
    color: var(--primary);
    margin-bottom: 1rem;
    font-size: 1.25rem;
    font-weight: 600;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  li {
    margin-bottom: 0.5rem;
  }

  a {
    color: var(--text-secondary);
    text-decoration: none;
    transition: color 0.2s;

    &:hover {
      color: var(--primary);
    }
  }
`;

const Footer: React.FC = () => {
  const currentYear = new Date().getFullYear();
  const { t } = useTranslation();

  return (
    <FooterWrapper>
      <Container>
        <FooterGrid $columns={{ desktop: 3, tablet: 2, mobile: 1 }} $gap="xl">
          <FooterSection>
            <h4>{t('footer.company')}</h4>
            <p style={{ color: 'var(--text-secondary)' }}>
              {t('footer.tagline')}
            </p>
          </FooterSection>

          <FooterSection>
            <h4>{t('footer.support')}</h4>
            <ul>
              <li><Link to="/help">{t('footer.helpCenter')}</Link></li>
              <li><Link to="/contact">{t('footer.contactUs')}</Link></li>
              <li><Link to="/faq">{t('footer.faq')}</Link></li>
            </ul>
          </FooterSection>

          <FooterSection>
            <h4>{t('footer.legal')}</h4>
            <ul>
              <li><Link to="/privacy">{t('footer.privacyPolicy')}</Link></li>
              <li><Link to="/terms">{t('footer.termsOfService')}</Link></li>
            </ul>
          </FooterSection>
        </FooterGrid>

        <p style={{ 
          color: 'var(--text-secondary)',
          textAlign: 'center',
          marginTop: '2rem'
        }}>
          {t('footer.copyright', { year: currentYear })}
        </p>
      </Container>
    </FooterWrapper>
  );
};

export default Footer;
