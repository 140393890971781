import React from 'react';
import styled, { css } from 'styled-components';

export type ButtonVariant = 'contained' | 'outlined' | 'text' | 'primary' | 'secondary';
export type ButtonSize = 'small' | 'medium' | 'large';
export type ButtonColor = 'primary' | 'secondary';

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: ButtonVariant;
  size?: ButtonSize;
  color?: ButtonColor;
  fullWidth?: boolean;
  disabled?: boolean;
  icon?: React.ReactNode;
}

const getButtonStyles = (variant: ButtonVariant = 'contained', color: ButtonColor = 'primary', theme: any) => {
  const colors = {
    primary: {
      main: theme.colors.primary,
      dark: theme.colors.primaryDark,
      text: theme.colors.white,
    },
    secondary: {
      main: theme.colors.secondary,
      dark: theme.colors.secondaryDark,
      text: theme.colors.white,
    },
  };

  // If variant is 'primary' or 'secondary', use it as the color
  const effectiveColor = (variant === 'primary' || variant === 'secondary') ? variant : color;
  const buttonColors = colors[effectiveColor];

  switch (variant) {
    case 'outlined':
      return css`
        color: ${buttonColors.main};
        border: 2px solid ${buttonColors.main};
        background: transparent;
        &:hover {
          background: ${buttonColors.main}10;
        }
      `;
    case 'text':
      return css`
        color: ${buttonColors.main};
        background: transparent;
        &:hover {
          background: ${buttonColors.main}10;
        }
      `;
    case 'primary':
    case 'secondary':
    case 'contained':
    default:
      return css`
        color: ${buttonColors.text};
        background: ${buttonColors.main};
        &:hover {
          background: ${buttonColors.dark};
        }
      `;
  }
};

const getSizeStyles = (size: ButtonSize = 'medium', theme: any) => {
  switch (size) {
    case 'small':
      return `${theme.spacing.xs} ${theme.spacing.sm}`;
    case 'large':
      return `${theme.spacing.md} ${theme.spacing.lg}`;
    default:
      return `${theme.spacing.sm} ${theme.spacing.md}`;
  }
};

export const Button = styled.button.attrs<ButtonProps>(props => ({
  ...props,
}))<ButtonProps>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  padding: ${({ size, theme }) => getSizeStyles(size, theme)};
  border: none;
  border-radius: ${({ theme }) => theme.borderRadius.sm};
  font-family: inherit;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  text-decoration: none;
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'auto')};
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};

  ${({ variant, color, theme }) => getButtonStyles(variant, color, theme)}

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px ${({ theme }) => theme.colors.primary}40;
  }

  ${({ icon }) =>
    icon &&
    css`
      display: inline-flex;
      align-items: center;
      gap: 0.5rem;
    `}
`;
