interface StructuredDataProps {
  type: 'product' | 'article' | 'faq' | 'organization';
  data?: any;
}

const StructuredData = ({ type, data }: StructuredDataProps) => {
  const getStructuredData = () => {
    switch (type) {
      case 'product':
        return {
          "@context": "https://schema.org",
          "@type": "SoftwareApplication",
          "name": "Flippose",
          "applicationCategory": "HealthApplication",
          "operatingSystem": "Web",
          "description": "AI-powered posture tracking and correction software",
          "offers": {
            "@type": "Offer",
            "price": data?.price || "0",
            "priceCurrency": "USD"
          },
          "aggregateRating": {
            "@type": "AggregateRating",
            "ratingValue": "4.8",
            "ratingCount": "150"
          }
        };

      case 'organization':
        return {
          "@context": "https://schema.org",
          "@type": "Organization",
          "name": "Flippose",
          "url": "https://flippose.com",
          "logo": "https://flippose.com/flippose_logo.png",
          "sameAs": [
            "https://twitter.com/flippose",
            "https://www.linkedin.com/company/flippose"
          ]
        };

      case 'faq':
        return {
          "@context": "https://schema.org",
          "@type": "FAQPage",
          "mainEntity": data?.faqs || []
        };

      case 'article':
        return {
          "@context": "https://schema.org",
          "@type": "Article",
          "headline": data?.title,
          "image": data?.image,
          "author": {
            "@type": "Organization",
            "name": "Flippose"
          },
          "publisher": {
            "@type": "Organization",
            "name": "Flippose",
            "logo": {
              "@type": "ImageObject",
              "url": "https://flippose.com/flippose_logo.png"
            }
          },
          "datePublished": data?.datePublished,
          "dateModified": data?.dateModified
        };

      default:
        return null;
    }
  };

  const structuredData = getStructuredData();

  if (!structuredData) return null;

  return (
    <script type="application/ld+json">
      {JSON.stringify(structuredData)}
    </script>
  );
};

export default StructuredData;
