import React from 'react';
import styled, { css, DefaultTheme } from 'styled-components';

type TextColor = 'primary' | 'secondary' | 'accent' | 'white';
type TypographyVariant = keyof DefaultTheme['typography'];

interface TextProps {
  $color?: TextColor;
  $variant?: TypographyVariant;
  $align?: 'left' | 'center' | 'right';
  $noMargin?: boolean;
}

const getColor = ($color: TextColor = 'primary', theme: DefaultTheme) => {
  switch ($color) {
    case 'secondary':
      return theme.colors.text.secondary;
    case 'accent':
      return theme.colors.accent;
    case 'white':
      return theme.colors.white;
    default:
      return theme.colors.text.primary;
  }
};

const getVariantStyles = (variant: TypographyVariant = 'body1', theme: DefaultTheme) => {
  if (!theme?.typography || !theme.typography[variant]) {
    console.warn(`Typography variant "${variant}" not found in theme`);
    return css``;
  }

  const variantStyle = theme.typography[variant];
  if (typeof variantStyle === 'string') {
    return css`
      font-family: ${variantStyle};
    `;
  }

  return css`
    font-size: ${variantStyle.fontSize};
    font-weight: ${variantStyle.fontWeight};
    line-height: ${variantStyle.lineHeight};
  `;
};

export const Text = styled.p.attrs<TextProps>((props) => ({
  ...props,
}))<TextProps>`
  ${({ theme, $variant }) => getVariantStyles($variant, theme)}
  color: ${({ theme, $color }) => getColor($color, theme)};
  text-align: ${({ $align }) => $align || 'inherit'};
  margin: ${({ $noMargin }) => ($noMargin ? '0' : 'inherit')};
`;

export default Text;
