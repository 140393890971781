import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { ipLanguageDetector } from './services/ipLanguageDetector';

// Create language detector instance
const languageDetector = new LanguageDetector();
languageDetector.addDetector(ipLanguageDetector);

// Get browser language as fallback
const getBrowserLanguage = () => {
  const languages = navigator.languages || [navigator.language];
  const supportedLanguages = [
    'en', 'es', 'zh', 'tl', 'vi', 'ar', 'fr', 'ko', 'de', 'hi',
    'pt', 'ru', 'it', 'pl', 'ja', 'fa', 'tr', 'uk', 'bn', 'ur',
    'id', 'th', 'el', 'ro'
  ];
  
  // Try to find the first supported language from browser's language list
  for (const lang of languages) {
    const shortLang = lang.split('-')[0].toLowerCase();
    if (supportedLanguages.includes(shortLang)) {
      return shortLang;
    }
  }
  
  return 'en'; // Default to English if no match
};

i18n
  .use(Backend)
  .use(languageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: getBrowserLanguage(),
    // Languages ordered by number of speakers globally and regional significance
    supportedLngs: [
      'en',    // English
      'es',    // Spanish
      'zh',    // Chinese
      'tl',    // Tagalog
      'vi',    // Vietnamese
      'ar',    // Arabic
      'fr',    // French
      'ko',    // Korean
      'de',    // German
      'hi',    // Hindi
      'pt',    // Portuguese
      'ru',    // Russian
      'it',    // Italian
      'pl',    // Polish
      'ja',    // Japanese
      'fa',    // Persian
      'tr',    // Turkish
      'uk',    // Ukrainian
      'bn',    // Bengali
      'ur',    // Urdu
      'id',    // Indonesian
      'th',    // Thai
      'el',    // Greek
      'ro'     // Romanian
    ],
    
    detection: {
      order: ['ipDetector', 'querystring', 'path', 'navigator', 'htmlTag', 'localStorage', 'cookie'],
      lookupQuerystring: 'lng',
      lookupCookie: 'i18next',
      lookupLocalStorage: 'i18nextLng',
      caches: ['localStorage', 'cookie'],
      cookieMinutes: 10080, // 7 days
      cookieDomain: window.location.hostname
    },

    backend: {
      loadPath: '/locales/{{lng}}/translation.json',
      requestOptions: {
        cache: 'default',
        mode: 'cors'
      },
      crossDomain: true
    },

    interpolation: {
      escapeValue: false
    },
    
    react: {
      useSuspense: false
    },

    debug: process.env.NODE_ENV === 'development'
  });

export default i18n;
