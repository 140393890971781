import { DefaultTheme } from 'styled-components';

const baseTheme = {
  typography: {
    fontFamily: "'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif",
    h1: {
      fontSize: '3rem',
      fontWeight: 700,
      lineHeight: 1.2
    },
    h2: {
      fontSize: '2.25rem',
      fontWeight: 700,
      lineHeight: 1.3
    },
    h3: {
      fontSize: '1.875rem',
      fontWeight: 600,
      lineHeight: 1.4
    },
    h4: {
      fontSize: '1.5rem',
      fontWeight: 600,
      lineHeight: 1.4
    },
    body1: {
      fontSize: '1rem',
      fontWeight: 400,
      lineHeight: 1.5
    },
    body2: {
      fontSize: '0.875rem',
      fontWeight: 400,
      lineHeight: 1.5
    },
    button: {
      fontSize: '1rem',
      fontWeight: 500,
      lineHeight: 1.75
    }
  },
  spacing: {
    xs: '0.25rem',
    sm: '0.5rem',
    md: '1rem',
    lg: '1.5rem',
    xl: '2rem',
    '2xl': '3rem',
    '3xl': '4rem',
    '4xl': '6rem',
    '5xl': '8rem',
    xxl: '3rem'
  },
  breakpoints: {
    mobile: '480px',
    tablet: '768px',
    laptop: '1024px',
    desktop: '1280px',
  },
  borderRadius: {
    none: '0',
    sm: '0.125rem',
    md: '0.375rem',
    lg: '0.5rem',
    xl: '0.75rem',
    '2xl': '1rem',
    '3xl': '1.5rem',
    full: '9999px',
  },
  zIndex: {
    hide: -1,
    auto: 'auto',
    base: 0,
    docked: 10,
    dropdown: 1000,
    sticky: 1100,
    banner: 1200,
    overlay: 1300,
    modal: 1400,
    popover: 1500,
    toast: 1600,
    tooltip: 1700,
  },
  transitions: {
    fast: '0.15s ease-in-out',
    medium: '0.25s ease-in-out',
    slow: '0.35s ease-in-out',
  },
  shadows: {
    none: 'none',
    sm: '0 1px 2px rgba(0, 0, 0, 0.05)',
    md: '0 4px 6px rgba(0, 0, 0, 0.1)',
    lg: '0 10px 15px rgba(0, 0, 0, 0.1)',
    xl: '0 20px 25px rgba(0, 0, 0, 0.15)',
  }
};

export const lightTheme: DefaultTheme = {
  ...baseTheme,
  colors: {
    primary: '#00B37E',
    primaryLight: '#00C48C',
    primaryDark: '#00A36F',
    secondary: '#6C757D',
    success: '#28A745',
    danger: '#DC3545',
    warning: '#FFC107',
    info: '#17A2B8',
    error: '#DC3545',
    accent: '#34D399',
    white: '#FFFFFF',
    background: {
      primary: '#F8F9FA',
      secondary: '#F1F3F5',
      tertiary: '#E9ECEF'
    },
    surface: '#FFFFFF',
    text: {
      primary: '#212529',
      secondary: '#495057',
      tertiary: '#868E96'
    },
    border: '#DEE2E6',
    shadow: 'rgba(0, 0, 0, 0.1)',
    gradient: {
      primary: 'linear-gradient(135deg, #00B37E 0%, #00A36F 100%)',
      text: 'linear-gradient(135deg, #00B37E 0%, #00A36F 100%)',
      accent: 'linear-gradient(135deg, #34D399 0%, #10B981 100%)'
    }
  }
};

export const darkTheme: DefaultTheme = {
  ...baseTheme,
  colors: {
    primary: '#00B37E',
    primaryLight: '#00C48C',
    primaryDark: '#00A36F',
    secondary: '#6C757D',
    success: '#28A745',
    danger: '#DC3545',
    warning: '#FFC107',
    info: '#17A2B8',
    error: '#EF4444',
    accent: '#34D399',
    white: '#FFFFFF',
    background: {
      primary: '#1A1D24',
      secondary: '#242830',
      tertiary: '#2D3139'
    },
    surface: '#2D3139',
    text: {
      primary: '#F8F9FA',
      secondary: '#E9ECEF',
      tertiary: '#DEE2E6'
    },
    border: '#373D47',
    shadow: 'rgba(0, 0, 0, 0.3)',
    gradient: {
      primary: 'linear-gradient(135deg, #00B37E 0%, #00A36F 100%)',
      text: 'linear-gradient(135deg, #00B37E 0%, #00A36F 100%)',
      accent: 'linear-gradient(135deg, #34D399 0%, #10B981 100%)'
    }
  }
};

export const theme = lightTheme;

declare module 'styled-components' {
  export interface DefaultTheme {
    typography: {
      fontFamily: string;
      h1: {
        fontSize: string;
        fontWeight: number;
        lineHeight: number;
      };
      h2: {
        fontSize: string;
        fontWeight: number;
        lineHeight: number;
      };
      h3: {
        fontSize: string;
        fontWeight: number;
        lineHeight: number;
      };
      h4: {
        fontSize: string;
        fontWeight: number;
        lineHeight: number;
      };
      body1: {
        fontSize: string;
        fontWeight: number;
        lineHeight: number;
      };
      body2: {
        fontSize: string;
        fontWeight: number;
        lineHeight: number;
      };
      button: {
        fontSize: string;
        fontWeight: number;
        lineHeight: number;
      };
    };
    spacing: {
      xs: string;
      sm: string;
      md: string;
      lg: string;
      xl: string;
      '2xl': string;
      '3xl': string;
      '4xl': string;
      '5xl': string;
      xxl: string;
    };
    breakpoints: {
      mobile: string;
      tablet: string;
      laptop: string;
      desktop: string;
    };
    borderRadius: {
      none: string;
      sm: string;
      md: string;
      lg: string;
      xl: string;
      '2xl': string;
      '3xl': string;
      full: string;
    };
    zIndex: {
      hide: number;
      auto: string;
      base: number;
      docked: number;
      dropdown: number;
      sticky: number;
      banner: number;
      overlay: number;
      modal: number;
      popover: number;
      toast: number;
      tooltip: number;
    };
    transitions: {
      fast: string;
      medium: string;
      slow: string;
    };
    shadows: {
      none: string;
      sm: string;
      md: string;
      lg: string;
      xl: string;
    };
    colors: {
      primary: string;
      primaryLight: string;
      primaryDark: string;
      secondary: string;
      success: string;
      danger: string;
      warning: string;
      info: string;
      error: string;
      accent: string;
      white: string;
      background: {
        primary: string;
        secondary: string;
        tertiary: string;
      };
      surface: string;
      text: {
        primary: string;
        secondary: string;
        tertiary: string;
      };
      border: string;
      shadow: string;
      gradient: {
        primary: string;
        text: string;
        accent: string;
      };
    };
  }
}
