import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import i18n from '../i18n';

interface SEOProps {
  title: string;
  description: string;
  keywords?: string;
  image?: string;
  url?: string;
  type?: string;
  noindex?: boolean;
  schema?: object;
}

interface LangLink {
  lang: string;
  url: string;
}

const SEO = ({ 
  title, 
  description, 
  keywords = '', 
  image = 'https://flippose.com/flippose_logo.png',
  url = typeof window !== 'undefined' ? window.location.href : '',
  type = 'website',
  noindex = false,
  schema
}: SEOProps) => {
  const { i18n: i18nInstance } = useTranslation();
  const currentLang = i18nInstance.language;
  const siteName = 'Flippose';
  const fullTitle = `${title} | ${siteName}`;

  // Generate hreflang links for all supported languages
  const supportedLangs = i18n.options.supportedLngs || [];
  const hrefLangLinks: LangLink[] = supportedLangs
    .filter((lng: string) => lng !== 'cimode')
    .map((lng: string) => ({
      lang: lng,
      // Replace current language in URL with target language
      url: url.replace(`/${currentLang}/`, `/${lng}/`)
    }));

  return (
    <Helmet>
      {/* Basic meta tags */}
      <html lang={currentLang} />
      <title>{fullTitle}</title>
      <meta name="description" content={description} />
      {keywords && <meta name="keywords" content={keywords} />}
      
      {/* Language alternates for SEO */}
      {hrefLangLinks.map(({ lang, url }: LangLink) => (
        <link key={lang} rel="alternate" hrefLang={lang} href={url} />
      ))}
      <link rel="alternate" hrefLang="x-default" href={url.replace(`/${currentLang}/`, '/en/')} />
      
      {/* Robots meta tag */}
      {noindex ? (
        <meta name="robots" content="noindex,nofollow" />
      ) : (
        <meta name="robots" content="index,follow,max-image-preview:large" />
      )}

      {/* Open Graph / Facebook */}
      <meta property="og:locale" content={currentLang} />
      <meta property="og:type" content={type} />
      <meta property="og:title" content={fullTitle} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image} />
      <meta property="og:url" content={url} />
      <meta property="og:site_name" content={siteName} />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" />

      {/* Twitter */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={fullTitle} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={image} />

      {/* Canonical URL */}
      <link rel="canonical" href={url} />

      {/* Structured Data */}
      {schema && (
        <script type="application/ld+json">
          {JSON.stringify(schema)}
        </script>
      )}
    </Helmet>
  );
};

export default SEO;
