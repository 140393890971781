import { DetectorOptions } from 'i18next-browser-languagedetector';
import { detectLanguageFromIP } from './geoLocation';
import i18next from 'i18next';

interface ExtendedDetectorOptions extends DetectorOptions {
  i18next?: typeof i18next;
}

interface IpDetector {
  name: string;
  lookup: (options: ExtendedDetectorOptions) => string | undefined;
  cacheUserLanguage: (lng: string) => void;
}

export const ipLanguageDetector: IpDetector = {
  name: 'ipDetector',
  
  lookup(options: ExtendedDetectorOptions): string | undefined {
    const cached = localStorage.getItem('i18nextIpLanguage');
    if (cached) {
      return cached;
    }
    
    // Since we can't use async/await in the lookup function,
    // we'll return undefined and let the next detector handle it
    detectLanguageFromIP().then(language => {
      if (language) {
        localStorage.setItem('i18nextIpLanguage', language);
        // The language will be used on next page load
        window.location.reload(); // Force reload to apply the new language
      }
    }).catch(error => {
      console.error('IP language detection failed:', error);
    });
    
    return undefined;
  },
  
  cacheUserLanguage(lng: string): void {
    localStorage.setItem('i18nextIpLanguage', lng);
  }
};
