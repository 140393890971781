import React, { createContext, useContext, useEffect, useState } from 'react';
import { 
  User, 
  setPersistence, 
  browserLocalPersistence,
  signInWithEmailAndPassword,
  signInWithPopup,
  GoogleAuthProvider,
  signOut as firebaseSignOut,
  onAuthStateChanged
} from 'firebase/auth';
import { auth } from '../firebase';
import { useNavigate } from 'react-router-dom';
import { doc, getDoc, setDoc, serverTimestamp } from 'firebase/firestore';
import { db } from '../firebase';

const googleProvider = new GoogleAuthProvider();

// Set persistence to LOCAL
setPersistence(auth, browserLocalPersistence)
  .catch((error) => {
    console.error("Error setting persistence:", error);
  });

interface AuthContextType {
  currentUser: User | null;
  user: User | null;
  loading: boolean;
  signIn: (email: string, password: string) => Promise<User>;
  signInWithGoogle: () => Promise<User>;
  signOut: () => Promise<void>;
  isEmailVerified: boolean;
  emailForVerification: string | null;
  setEmailForVerification: (email: string | null) => void;
  getIdToken: () => Promise<string | null>;
}

const defaultContext: AuthContextType = {
  currentUser: null,
  user: null,
  loading: true,
  signIn: async () => { throw new Error('Not implemented') },
  signInWithGoogle: async () => { throw new Error('Not implemented') },
  signOut: async () => { throw new Error('Not implemented') },
  isEmailVerified: false,
  emailForVerification: null,
  setEmailForVerification: () => { throw new Error('Not implemented') },
  getIdToken: async () => null
};

const AuthContext = createContext<AuthContextType>(defaultContext);

const useAuth = () => {
  return useContext(AuthContext);
};

// Route constants to ensure consistency across the app
const ROUTES = {
  AUTH: {
    SIGN_IN: '/signin',
    SIGN_UP: '/signup',
    VERIFY_SIGN_IN: '/verify-signin',
    VERIFY_EMAIL: '/verify-email',
    RESET_PASSWORD: '/reset-password',
    FORGOT_PASSWORD: '/forgot-password'
  },
  APP: {
    POSE: '/pose',
    ACCOUNT: '/account'
  }
} as const;

// All auth-related paths that should trigger redirect after login
const AUTH_PATHS = Object.values(ROUTES.AUTH);

type AuthRoute = typeof ROUTES.AUTH[keyof typeof ROUTES.AUTH];

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);
  const [isEmailVerified, setIsEmailVerified] = useState(false);
  const [emailForVerification, setEmailForVerification] = useState<string | null>(null);
  const [isInitialAuth, setIsInitialAuth] = useState(true);
  const navigate = useNavigate();

  const createUserDocument = async (user: User) => {
    const userRef = doc(db, 'users', user.uid);
    const userSnap = await getDoc(userRef);

    if (!userSnap.exists()) {
      try {
        await setDoc(userRef, {
          email: user.email,
          createdAt: new Date().toISOString(),
          lastLogin: new Date().toISOString(),
          plan: 'free',
          stripeCustomerId: '',
          stripeSubscriptionId: '',
          updatedAt: new Date().toISOString()
        }, { merge: false });
        console.log('Created user document for', user.uid);
      } catch (error) {
        console.error('Error creating user document:', error);
      }
    } else {
      // Update lastLogin
      try {
        await setDoc(userRef, {
          lastLogin: new Date().toISOString(),
          updatedAt: new Date().toISOString()
        }, { merge: true });
      } catch (error) {
        console.error('Error updating lastLogin:', error);
      }
    }
  };

  const signOut = async () => {
    await firebaseSignOut(auth);
    setCurrentUser(null);
    setIsEmailVerified(false);
    setEmailForVerification(null);
  };

  const signIn = async (email: string, password: string): Promise<User> => {
    const result = await signInWithEmailAndPassword(auth, email, password);
    const user = result.user;
    
    await user.reload();
    if (!user.emailVerified) {
      setEmailForVerification(user.email);
      await signOut();
      throw new Error('Please verify your email before signing in');
    }
    
    setCurrentUser(user);
    setIsEmailVerified(true);
    setEmailForVerification(null);
    return user;
  };

  const signInWithGoogle = async (): Promise<User> => {
    try {
      const result = await signInWithPopup(auth, googleProvider);
      const user = result.user;
      
      await user.reload();
      if (!user.emailVerified) {
        setEmailForVerification(user.email);
        await signOut();
        throw new Error('Please verify your email before signing in');
      }
      
      // Get the Google OAuth access token
      const credential = GoogleAuthProvider.credentialFromResult(result);
      const token = credential?.accessToken;
      
      console.log('Google Sign In Success:', {
        email: user.email,
        verified: user.emailVerified,
        hasToken: !!token
      });
      
      setCurrentUser(user);
      setIsEmailVerified(true);
      setEmailForVerification(null);
      return user;
    } catch (error: any) {
      console.error('Google Sign In Error:', error);
      // Handle specific error cases
      if (error.code === 'auth/popup-blocked') {
        throw new Error('Please allow popups for this website to sign in with Google');
      }
      throw error;
    }
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        // Reload user to get fresh emailVerified status
        await user.reload();
        
        if (!user.emailVerified) {
          // If email is not verified, sign out and redirect to verification page
          setEmailForVerification(user.email);
          await signOut();
          navigate('/verify-email', { 
            state: { email: user.email },
            replace: true 
          });
          return;
        }

        setIsEmailVerified(true);
        setCurrentUser(user);
        await createUserDocument(user);
      } else {
        setIsEmailVerified(false);
        setCurrentUser(null);
      }
      setLoading(false);
    });

    return unsubscribe;
  }, [navigate]);

  // Only redirect to /pose on initial sign in
  useEffect(() => {
    if (currentUser && !loading && isInitialAuth) {
      setIsInitialAuth(false);
      const currentPath = window.location.pathname;
      // Only redirect if we're on an auth-related page
      if (AUTH_PATHS.includes(currentPath as AuthRoute)) {
        navigate('/pose');
      }
    }
  }, [currentUser, loading, navigate, isInitialAuth]);

  const getIdToken = async () => {
    if (currentUser) {
      return await currentUser.getIdToken();
    } else {
      return null;
    }
  };

  const value = {
    currentUser,
    user: currentUser,
    loading,
    signIn,
    signInWithGoogle,
    signOut,
    isEmailVerified,
    emailForVerification,
    setEmailForVerification,
    getIdToken
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}

export { AuthContext, useAuth };
