import React, { createContext, useContext, useState, ReactNode, useEffect } from 'react';
import { auth } from '../firebase';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebase';

interface Features {
  basic_posture: boolean;
  sensitivity_control: boolean;
  background_notifications: boolean;
  advanced_metrics: boolean;
  sound_alerts: boolean;
}

interface SubscriptionStatus {
  plan: 'free' | 'pro';
  status: 'active' | 'inactive';
  features: Features;
}

interface UpgradeContextType {
  isUpgrading: boolean;
  isPro: boolean;
  features: Features;
  subscriptionStatus: SubscriptionStatus | null;
  setUpgrading: (upgrading: boolean) => void;
  setIsPro: (isPro: boolean) => void;
  refreshSubscription: () => Promise<void>;
}

const defaultFeatures: Features = {
  basic_posture: true,
  sensitivity_control: false,
  background_notifications: false,
  advanced_metrics: false,
  sound_alerts: false,
};

const proFeatures: Features = {
  basic_posture: true,
  sensitivity_control: true,
  background_notifications: true,
  advanced_metrics: true,
  sound_alerts: true,
};

const defaultContext: UpgradeContextType = {
  isUpgrading: false,
  isPro: false,
  features: proFeatures,
  subscriptionStatus: null,
  setUpgrading: () => {},
  setIsPro: () => {},
  refreshSubscription: async () => {},
};

const UpgradeContext = createContext<UpgradeContextType>(defaultContext);

export const useUpgrade = () => {
  const context = useContext(UpgradeContext);
  if (!context) {
    throw new Error('useUpgrade must be used within an UpgradeProvider');
  }
  return context;
};

export const UpgradeProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [isUpgrading, setUpgrading] = useState(false);
  const [isPro, setIsPro] = useState(false);
  const [features, setFeatures] = useState<Features>(defaultFeatures);
  const [subscriptionStatus, setSubscriptionStatus] = useState<SubscriptionStatus | null>(null);
  const [authChecked, setAuthChecked] = useState(false);

  const setDefaultState = () => {
    setIsPro(false);
    setFeatures(defaultFeatures);
    setSubscriptionStatus({
      plan: 'free',
      status: 'active',
      features: defaultFeatures
    });
  };

  const refreshSubscription = async () => {
    const user = auth.currentUser;
    if (!user || !authChecked) {
      console.log('No user logged in or auth not checked, setting default features');
      setDefaultState();
      return;
    }

    try {
      // Wait a bit to ensure Firebase auth token is properly propagated
      await new Promise(resolve => setTimeout(resolve, 1000));
      
      console.log('Fetching subscription for user:', user.uid);
      const docRef = doc(db, 'subscriptions', user.uid);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const data = docSnap.data();
        const status: SubscriptionStatus = {
          plan: data.plan || 'free',
          status: data.status || 'inactive',
          features: { ...defaultFeatures, ...(data.features || {}) }
        };

        console.log('Subscription data:', status);
        const newIsPro = status.plan === 'pro' && status.status === 'active';
        setIsPro(newIsPro);
        setFeatures(status.features);
        setSubscriptionStatus(status);
      } else {
        console.log('No subscription found, using default subscription');
        setDefaultState();
      }
    } catch (error) {
      console.error('Error fetching subscription:', error);
      // If we get a permission error, we'll just use the default free features
      setDefaultState();
    }
  };

  // Listen to auth state changes
  useEffect(() => {
    console.log('Setting up auth state listener');
    const unsubscribe = auth.onAuthStateChanged((user) => {
      console.log('Auth state changed:', user ? `User ${user.uid}` : 'No user');
      setAuthChecked(true);
      if (user) {
        refreshSubscription();
      } else {
        setDefaultState();
      }
    });

    return () => {
      console.log('Cleaning up auth state listener');
      unsubscribe();
    };
  }, []);

  const value: UpgradeContextType = {
    isUpgrading,
    isPro,
    features,
    subscriptionStatus,
    setUpgrading,
    setIsPro,
    refreshSubscription
  };

  return (
    <UpgradeContext.Provider value={value}>
      {children}
    </UpgradeContext.Provider>
  );
};

export default UpgradeContext;
