import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes, faMoon, faSun, faChevronDown, faUser, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { Container } from '../common';
import { useAuth } from '../../contexts/AuthContext';
import { useTheme } from '../../contexts/ThemeContext';
import { useTranslation } from 'react-i18next';
import LanguageSelector from '../common/LanguageSelector';

const Nav = styled.nav`
  background-color: ${({ theme }) => theme.colors.surface};
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  padding: 1rem 0;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
`;

const Logo = styled(Link)`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 1.5rem;
  font-weight: bold;
  text-decoration: none;
  transition: color 0.2s;

  &:hover {
    color: ${({ theme }) => theme.colors.primaryDark};
  }
`;

const MenuButton = styled.button`
  display: none;
  background: none;
  border: none;
  color: ${({ theme }) => theme.colors.primary};
  font-size: 1.5rem;
  cursor: pointer;
  padding: 0.5rem;
  transition: color 0.2s;

  &:hover {
    color: ${({ theme }) => theme.colors.primaryDark};
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    display: block;
  }
`;

interface NavLinksProps {
  $isOpen: boolean;
}

const NavLinks = styled.div<NavLinksProps>`
  display: flex;
  align-items: center;
  gap: 2rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    display: ${({ $isOpen }) => ($isOpen ? 'flex' : 'none')};
    flex-direction: column;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background-color: ${({ theme }) => theme.colors.surface};
    padding: 1rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
`;

interface NavLinkProps {
  $active?: boolean;
}

const NavLink = styled(Link)<NavLinkProps>`
  text-decoration: none;
  font-weight: 500;
  color: ${({ theme, $active }) =>
    $active ? theme.colors.primary : theme.colors.text.primary};
  transition: color 0.2s ease;

  &:hover {
    color: ${({ theme }) => theme.colors.primary};
  }
`;

const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const DropdownContainer = styled.div`
  position: relative;
  display: inline-block;
`;

const DropdownButton = styled.button`
  background: none;
  border: none;
  color: ${({ theme }) => theme.colors.text.primary};
  font-size: 1rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem;
  transition: color 0.2s;

  &:hover {
    color: ${({ theme }) => theme.colors.primary};
  }

  svg {
    font-size: 0.8rem;
  }
`;

const DropdownContent = styled.div<{ $isOpen: boolean }>`
  display: ${({ $isOpen }) => ($isOpen ? 'block' : 'none')};
  position: absolute;
  right: 0;
  top: 100%;
  min-width: 200px;
  background-color: ${({ theme }) => theme.colors.surface};
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  padding: 0.5rem 0;
  z-index: 1000;
`;

const DropdownItem = styled(Link)`
  color: ${({ theme }) => theme.colors.text.primary};
  padding: 0.75rem 1rem;
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 0.75rem;
  transition: all 0.2s;

  &:hover {
    background-color: ${({ theme }) => theme.colors.background.secondary};
    color: ${({ theme }) => theme.colors.primary};
  }

  svg {
    width: 16px;
  }
`;

const DropdownDivider = styled.hr`
  border: none;
  border-top: 1px solid ${({ theme }) => theme.colors.border};
  margin: 0.5rem 0;
`;

const ThemeToggle = styled.button`
  background: none;
  border: none;
  color: ${({ theme }) => theme.colors.text.primary};
  font-size: 1.2rem;
  cursor: pointer;
  padding: 0.5rem;
  transition: color 0.2s;

  &:hover {
    color: ${({ theme }) => theme.colors.primary};
  }
`;

function AccountDropdown({ isOpen, toggleDropdown }: { isOpen: boolean; toggleDropdown: () => void }) {
  const { t } = useTranslation();
  const { signOut } = useAuth();

  const handleSignOut = async (e: React.MouseEvent) => {
    e.preventDefault();
    try {
      await signOut();
    } catch (error) {
      console.error('Failed to sign out:', error);
    }
  };

  return (
    <DropdownContainer className="account-dropdown">
      <DropdownButton onClick={toggleDropdown}>
        {t('common.account')}
        <FontAwesomeIcon icon={faChevronDown} />
      </DropdownButton>
      <DropdownContent $isOpen={isOpen}>
        <DropdownItem to="/account">
          <FontAwesomeIcon icon={faUser} />
          {t('common.accountDetails')}
        </DropdownItem>
        <DropdownDivider />
        <DropdownItem to="#" onClick={handleSignOut}>
          <FontAwesomeIcon icon={faSignOutAlt} />
          {t('common.signOut')}
        </DropdownItem>
      </DropdownContent>
    </DropdownContainer>
  );
}

function Navbar() {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const { currentUser } = useAuth();
  const location = useLocation();
  const { themeMode, toggleTheme } = useTheme();

  const isActive = (path: string) => location.pathname === path;

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownOpen && !(event.target as Element).closest('.account-dropdown')) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => document.removeEventListener('click', handleClickOutside);
  }, [dropdownOpen]);

  return (
    <Nav>
      <Container>
        <Flex>
          <Logo to="/">Flippose</Logo>
          <MenuButton onClick={() => setIsOpen(!isOpen)}>
            <FontAwesomeIcon icon={isOpen ? faTimes : faBars} />
          </MenuButton>
          <NavLinks $isOpen={isOpen}>
            {currentUser ? (
              <>
                <NavLink to="/pose" $active={isActive('/pose')}>
                  {t('common.poseDetection')}
                </NavLink>
                <NavLink to="/faq" $active={isActive('/faq')}>
                  {t('common.faq')}
                </NavLink>
                <AccountDropdown isOpen={dropdownOpen} toggleDropdown={() => setDropdownOpen(!dropdownOpen)} />
              </>
            ) : (
              <>
                <NavLink to="/faq" $active={isActive('/faq')}>
                  {t('common.faq')}
                </NavLink>
                <NavLink to="/signin">{t('common.signIn')}</NavLink>
                <NavLink to="/signup">{t('common.signUp')}</NavLink>
              </>
            )}
            <LanguageSelector />
            <ThemeToggle onClick={toggleTheme} aria-label="Toggle theme">
              <FontAwesomeIcon icon={themeMode === 'light' ? faMoon : faSun} />
            </ThemeToggle>
          </NavLinks>
        </Flex>
      </Container>
    </Nav>
  );
}

export default Navbar;
