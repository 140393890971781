import styled from 'styled-components';
import { Text } from './Text';

interface GradientTextProps {
  variant?: 'primary' | 'text' | 'accent';
}

export const GradientText = styled(Text)<GradientTextProps>`
  background: ${({ theme, variant = 'text' }) => theme.colors.gradient[variant]};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
`;
