interface GeoResponse {
  country_code: string;
  country_name: string;
}

const countryToLanguage: { [key: string]: string } = {
  // Major English-speaking countries
  US: 'en', GB: 'en', AU: 'en', CA: 'en', NZ: 'en',
  
  // Spanish-speaking countries
  ES: 'es', MX: 'es', AR: 'es', CO: 'es', CL: 'es',
  
  // Asian countries
  CN: 'zh', TW: 'zh', HK: 'zh', // Chinese
  KR: 'ko', // Korean
  JP: 'ja', // Japanese
  VN: 'vi', // Vietnamese
  PH: 'tl', // Philippines (Tagalog)
  ID: 'id', // Indonesian
  TH: 'th', // Thai
  
  // European countries
  DE: 'de', // German
  FR: 'fr', // French
  IT: 'it', // Italian
  PT: 'pt', // Portuguese
  PL: 'pl', // Polish
  RU: 'ru', // Russian
  UA: 'uk', // Ukrainian
  GR: 'el', // Greek
  RO: 'ro', // Romanian
  TR: 'tr', // Turkish
  
  // Middle Eastern & South Asian countries
  SA: 'ar', AE: 'ar', EG: 'ar', // Arabic
  IR: 'fa', // Persian
  IN: 'hi', // Hindi (India)
  BD: 'bn', // Bengali (Bangladesh)
  PK: 'ur', // Urdu (Pakistan)
};

export const detectLanguageFromIP = async (): Promise<string | null> => {
  try {
    const response = await fetch('https://ipapi.co/json/');
    const data: GeoResponse = await response.json();
    
    // Get language code from country code
    const detectedLanguage = countryToLanguage[data.country_code];
    
    return detectedLanguage || null;
  } catch (error) {
    console.error('Error detecting language from IP:', error);
    return null;
  }
};
