import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider, connectAuthEmulator, signInWithPopup } from 'firebase/auth';
import { 
  getFirestore, 
  collection, 
  doc, 
  setDoc, 
  getDoc, 
  connectFirestoreEmulator,
  writeBatch,
  deleteDoc,
  type Firestore
} from 'firebase/firestore';
import { getAnalytics, logEvent, Analytics } from 'firebase/analytics';

const firebaseConfig = {
  apiKey: "AIzaSyCoRq9VNbnGf3VgU-R6ewfFbiLpjznRztI",
  authDomain: "posturetracker-a5c59.firebaseapp.com",
  databaseURL: "https://posturetracker-a5c59-default-rtdb.firebaseio.com",
  projectId: "posturetracker-a5c59",
  storageBucket: "posturetracker-a5c59.firebasestorage.app",
  messagingSenderId: "494711869546",
  appId: "1:494711869546:web:eac6fec7690907b8d23a13",
  measurementId: "G-KMTMRWP00X"
};

// Debug: Log Firebase config (without sensitive data)
console.log('Firebase Config:', {
  authDomain: firebaseConfig.authDomain,
  projectId: firebaseConfig.projectId,
  hasApiKey: !!firebaseConfig.apiKey,
  apiKeyLength: firebaseConfig.apiKey?.length
});

if (!firebaseConfig.apiKey) {
  throw new Error('Firebase API key is missing');
}

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const provider = new GoogleAuthProvider();
provider.setCustomParameters({
  prompt: 'select_account',
});

// Initialize Analytics (only in browser environment)
let analytics: Analytics | null = null;
if (typeof window !== 'undefined') {
  try {
    analytics = getAnalytics(app);
    console.log('Firebase Analytics initialized successfully');
  } catch (error) {
    console.error('Error initializing Firebase Analytics:', error);
  }
}

// Configure Auth settings
auth.useDeviceLanguage(); // Use the device's language

// Add authorized domains for development and staging
const allowedDomains = ['localhost', 'flippose.com', 'test.flippose.com', 'test-flippose.web.app'];
if (allowedDomains.some(domain => window.location.hostname.includes(domain))) {
  auth.useDeviceLanguage();
  provider.addScope('email');
  provider.addScope('profile');
}

// Connect to emulator only if explicitly enabled
const useEmulator = process.env.REACT_APP_USE_FIREBASE_EMULATOR === 'true';
if (useEmulator && process.env.NODE_ENV === 'development') {
  console.log('Using Firebase Auth Emulator');
  try {
    connectAuthEmulator(auth, 'http://localhost:9099', { disableWarnings: true });
    connectFirestoreEmulator(db, 'localhost', 8080);
  } catch (error) {
    console.warn('Failed to connect to Firebase Emulators:', error);
  }
}

// Helper function to initialize user collections
export async function initializeUserCollections(userId: string, email: string, firestore: Firestore = db) {
  try {
    console.log('Initializing user collections for:', userId);
    const batch = writeBatch(firestore);

    // Initialize user document
    const userRef = doc(firestore, 'users', userId);
    const userDoc = await getDoc(userRef);
    if (!userDoc.exists()) {
      console.log('Creating user document');
      batch.set(userRef, {
        email,
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString()
      });
    }

    // Initialize account document
    const accountRef = doc(firestore, 'accounts', userId);
    const accountDoc = await getDoc(accountRef);
    if (!accountDoc.exists()) {
      console.log('Creating account document');
      batch.set(accountRef, {
        email,
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
        subscription: {
          plan: 'free',
          status: 'free',
          stripeCustomerId: null,
          stripeSubscriptionId: null,
          nextBillingDate: null,
          updatedAt: new Date().toISOString()
        }
      }, { merge: false });
    }

    // Initialize subscription document
    const subscriptionRef = doc(firestore, 'subscriptions', userId);
    const subscriptionDoc = await getDoc(subscriptionRef);
    if (!subscriptionDoc.exists()) {
      console.log('Creating subscription document');
      batch.set(subscriptionRef, {
        billing_info: {
          auto_renew: false,
          stripeCustomerId: null,
          next_billing_date: null,
          price_id: null,
          start_date: new Date().toISOString(),
          stripeSubscriptionId: null,
          updatedAt: new Date().toISOString()
        },
        cancelAtPeriodEnd: false,
        current_plan: 'free',
        email: email,
        plan: 'free',
        plan_type: 'free',
        status: 'free',
        stripeCustomerId: null,
        stripeSubscriptionId: null,
        updatedAt: new Date().toISOString()
      }, { merge: false });
    }

    // Initialize free users usage document
    const usageRef = doc(firestore, 'free_users_usage', userId);
    const usageDoc = await getDoc(usageRef);
    if (!usageDoc.exists()) {
      console.log('Creating usage document');
      batch.set(usageRef, {
        userId,
        usage_minutes: 0,
        last_usage: null,
        updatedAt: new Date().toISOString()
      });
    }

    await batch.commit();
    console.log('Successfully initialized user collections');
  } catch (error) {
    console.error('Error initializing user collections:', error);
    throw error;
  }
}

export { auth, db, doc, deleteDoc, provider, GoogleAuthProvider, signInWithPopup, analytics };
export default app;
