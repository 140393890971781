import styled from 'styled-components';
import { theme } from '../../styles/theme';

interface FlexProps {
  $direction?: 'row' | 'column' | 'row-reverse' | 'column-reverse';
  $align?: 'start' | 'center' | 'end' | 'stretch' | 'baseline';
  $justify?: 'start' | 'center' | 'end' | 'between' | 'around' | 'evenly';
  $wrap?: 'nowrap' | 'wrap' | 'wrap-reverse';
  $gap?: keyof typeof theme.spacing;
  $fullWidth?: boolean;
  $fullHeight?: boolean;
}

export const Flex = styled.div<FlexProps>`
  display: flex;
  flex-direction: ${props => props.$direction || 'row'};
  align-items: ${props => {
    switch (props.$align) {
      case 'start': return 'flex-start';
      case 'end': return 'flex-end';
      case 'center': return 'center';
      case 'stretch': return 'stretch';
      case 'baseline': return 'baseline';
      default: return 'center';
    }
  }};
  justify-content: ${props => {
    switch (props.$justify) {
      case 'start': return 'flex-start';
      case 'end': return 'flex-end';
      case 'center': return 'center';
      case 'between': return 'space-between';
      case 'around': return 'space-around';
      case 'evenly': return 'space-evenly';
      default: return 'flex-start';
    }
  }};
  flex-wrap: ${props => props.$wrap || 'nowrap'};
  gap: ${props => props.$gap ? theme.spacing[props.$gap] : '0'};
  width: ${props => props.$fullWidth ? '100%' : 'auto'};
  height: ${props => props.$fullHeight ? '100%' : 'auto'};
`;
