import React, { Suspense, useEffect } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import './i18n';
import { GlobalStyles } from './styles/GlobalStyles';
import { theme } from './styles/theme';
import { HelmetProvider } from 'react-helmet-async';
import { ThemeProvider } from './contexts/ThemeContext';
import { AuthProvider } from './contexts/AuthContext';
import { UpgradeProvider } from './contexts/UpgradeContext';
import { analytics } from './firebase';
import { logEvent, Analytics } from 'firebase/analytics';

// Components
import Layout from './components/layout/Layout';
import PasswordProtection from './components/auth/PasswordProtection';
import ProtectedRoute from './components/auth/ProtectedRoute';
import SEO from './components/SEO';
import StructuredData from './components/StructuredData';

// Lazy-loaded pages
const Home = React.lazy(() => import('./pages/Home'));
const Features = React.lazy(() => import('./pages/Features'));
const Upgrade = React.lazy(() => import('./pages/Upgrade'));
const Download = React.lazy(() => import('./pages/Download'));
const Terms = React.lazy(() => import('./pages/Terms'));
const Privacy = React.lazy(() => import('./pages/Privacy'));
const Contact = React.lazy(() => import('./pages/Contact'));
const Help = React.lazy(() => import('./pages/Help'));
const FAQ = React.lazy(() => import('./pages/FAQ'));
const SignIn = React.lazy(() => import('./pages/SignIn'));
const VerifySignIn = React.lazy(() => import('./pages/VerifySignIn'));
const SignUp = React.lazy(() => import('./pages/SignUp'));
const ForgotPassword = React.lazy(() => import('./pages/ForgotPassword'));
const ResetPassword = React.lazy(() => import('./pages/ResetPassword'));
const PoseDetection = React.lazy(() => import('./pages/PoseDetection'));
const Account = React.lazy(() => import('./pages/Account'));
const SubscriptionSuccess = React.lazy(() => import('./pages/SubscriptionSuccess'));
const ManageSubscription = React.lazy(() => import('./pages/ManageSubscription'));
const UpdatePayment = React.lazy(() => import('./pages/UpdatePayment'));
const ReactivateSubscription = React.lazy(() => import('./pages/ReactivateSubscription'));
const VerifyPhone = React.lazy(() => import('./pages/VerifyPhone'));
const VerifyEmail = React.lazy(() => import('./pages/VerifyEmail'));
const BillingHistory = React.lazy(() => import('./pages/BillingHistory'));
const NotFound = React.lazy(() => import('./pages/NotFound'));

function App() {
  const isMaintenanceMode = process.env.REACT_APP_MAINTENANCE_MODE === 'true';
  const maintenancePassword = process.env.REACT_APP_MAINTENANCE_PASSWORD;
  const location = useLocation();

  // Track page views
  useEffect(() => {
    if (analytics) {
      // Track page view
      logEvent(analytics as Analytics, 'page_view', {
        page_title: document.title,
        page_location: window.location.href,
        page_path: location.pathname,
      });
      console.log('Analytics page_view event logged:', location.pathname);
    }
  }, [location]);

  const appContent = (
    <HelmetProvider>
      <ThemeProvider>
        <AuthProvider>
          <UpgradeProvider>
            <GlobalStyles />
            {/* Base SEO */}
            <SEO 
              title="AI Posture Coach"
              description="Improve your posture with real-time AI feedback. Get personalized recommendations and track your progress with Flippose."
              keywords="posture tracking, AI posture, posture correction, ergonomics, health tech, posture analysis"
            />
            {/* Organization Structured Data */}
            <StructuredData type="organization" />
            <Suspense fallback={<div>Loading...</div>}>
              <Routes>
                <Route element={<Layout />}>
                  <Route path="/" element={<Home />} />
                  <Route path="/features" element={<Features />} />
                  <Route path="/upgrade" element={<Upgrade />} />
                  <Route path="/download" element={<Download />} />
                  <Route path="/terms" element={<Terms />} />
                  <Route path="/privacy" element={<Privacy />} />
                  <Route path="/contact" element={<Contact />} />
                  <Route path="/help" element={<Help />} />
                  <Route path="/faq" element={<FAQ />} />
                  <Route path="/signin" element={<SignIn />} />
                  <Route path="/verify-signin" element={<VerifySignIn />} />
                  <Route path="/signup" element={<SignUp />} />
                  <Route path="/forgot-password" element={<ForgotPassword />} />
                  <Route path="/reset-password" element={<ResetPassword />} />
                  
                  {/* Protected Routes */}
                  <Route path="/pose" element={<PoseDetection />} />
                  <Route path="/account" element={
                    <ProtectedRoute>
                      <Account />
                    </ProtectedRoute>
                  } />
                  <Route path="/subscription/success" element={<SubscriptionSuccess />} />
                  <Route path="/subscription/manage" element={<ManageSubscription />} />
                  <Route path="/subscription/update-payment" element={<UpdatePayment />} />
                  <Route path="/subscription/reactivate" element={<ReactivateSubscription />} />
                  <Route path="/verify-phone" element={<VerifyPhone />} />
                  <Route path="/verify-email" element={<VerifyEmail />} />
                  <Route path="/billing-history" element={<BillingHistory />} />
                  {/* 404 Route */}
                  <Route path="*" element={<NotFound />} />
                </Route>
              </Routes>
            </Suspense>
          </UpgradeProvider>
        </AuthProvider>
      </ThemeProvider>
    </HelmetProvider>
  );

  return isMaintenanceMode ? (
    <PasswordProtection password={maintenancePassword}>{appContent}</PasswordProtection>
  ) : (
    appContent
  );
}

export default App;
