import React, { useRef, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobe } from '@fortawesome/free-solid-svg-icons';

const LanguageButton = styled.button<{ $isOpen?: boolean }>`
  background: transparent;
  border: none;
  color: #e1e2e4;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px;
  font-size: 1rem;
  border-radius: 4px;
  transition: background-color 0.2s;

  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }

  svg {
    font-size: 1.2rem;
  }
`;

const Dropdown = styled.div<{ $isOpen: boolean }>`
  position: absolute;
  top: 100%;
  right: 0;
  background-color: #1a1b1e;
  border: 1px solid #2c2d31;
  border-radius: 4px;
  padding: 8px 0;
  display: ${props => (props.$isOpen ? 'block' : 'none')};
  z-index: 1000;
  max-height: 400px;
  overflow-y: auto;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #1a1b1e;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #2c2d31;
    border-radius: 4px;
    border: 2px solid #1a1b1e;
  }
`;

const LanguageOption = styled.button<{ $isActive: boolean }>`
  background: transparent;
  border: none;
  color: #e1e2e4;
  cursor: pointer;
  display: block;
  width: 100%;
  text-align: left;
  padding: 8px 16px;
  font-size: 0.9rem;
  transition: background-color 0.2s;
  background-color: ${props => props.$isActive ? '#2c2d31' : 'transparent'};

  &:hover {
    background-color: #2c2d31;
  }

  &:focus {
    outline: none;
    background-color: #2c2d31;
  }
`;

const Container = styled.div`
  position: relative;
  display: inline-block;
`;

const LanguageSelector: React.FC = () => {
  const { i18n, t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node) &&
        buttonRef.current &&
        !buttonRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const languages = [
    { code: 'en', name: 'English' },
    { code: 'es', name: 'Español' },
    { code: 'zh', name: '中文' },
    { code: 'tl', name: 'Tagalog' },
    { code: 'vi', name: 'Tiếng Việt' },
    { code: 'ar', name: 'العربية' },
    { code: 'fr', name: 'Français' },
    { code: 'ko', name: '한국어' },
    { code: 'de', name: 'Deutsch' },
    { code: 'hi', name: 'हिन्दी' },
    { code: 'pt', name: 'Português' },
    { code: 'ru', name: 'Русский' },
    { code: 'it', name: 'Italiano' },
    { code: 'pl', name: 'Polski' },
    { code: 'ja', name: '日本語' },
    { code: 'fa', name: 'فارسی' },
    { code: 'tr', name: 'Türkçe' },
    { code: 'uk', name: 'Українська' },
    { code: 'bn', name: 'বাংলা' },
    { code: 'ur', name: 'اردو' },
    { code: 'id', name: 'Bahasa Indonesia' },
    { code: 'th', name: 'ไทย' },
    { code: 'el', name: 'Ελληνικά' },
    { code: 'ro', name: 'Română' }
  ];

  const handleLanguageChange = (languageCode: string) => {
    i18n.changeLanguage(languageCode);
    setIsOpen(false);
  };

  return (
    <Container>
      <LanguageButton
        ref={buttonRef}
        onClick={() => setIsOpen(!isOpen)}
        $isOpen={isOpen}
        aria-label={t('common.language')}
        title={t('common.language')}
      >
        <FontAwesomeIcon icon={faGlobe} />
        <span>{t('common.language')}</span>
      </LanguageButton>
      <Dropdown $isOpen={isOpen} ref={dropdownRef}>
        {languages.map(({ code, name }) => (
          <LanguageOption
            key={code}
            onClick={() => handleLanguageChange(code)}
            $isActive={i18n.language === code}
          >
            {name}
          </LanguageOption>
        ))}
      </Dropdown>
    </Container>
  );
};

export default LanguageSelector;
